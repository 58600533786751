import '../css/app.css'
import Vue from 'vue'
import cs from 'vuetify/src/locale/cs'
import Vuetify from 'vuetify'
import Axios from './ajax'
import Qs from 'qs'
import { errorHandlerMixin } from './errorHandlerMixin.js'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  lang: {
    locales: { cs },
    current: 'cs'
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: { disable: true }
})

export default {
  el: '#app',
  vuetify,
  mixins: [
    errorHandlerMixin
  ],
  data() {
    return {
      menu: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      tab: null,
      contactForm: {
        key: 1,
        personalName: null,
        email: null,
        phone: null,
        city: null,
        body: null
      }
    }
  },
  methods: {
    toggleMenu() {
      this.menu = !this.menu;
    },
    submitContactForm () {
      this.clearViolations()
      Axios.post('/kontakt', Qs.stringify({
        personalName: this.contactForm.personalName,
        email: this.contactForm.email,
        phone: this.contactForm.phone,
        city: this.contactForm.city,
        body: this.contactForm.body
      })).then(() => {
        alert('Formulář byl odeslán.')
        this.contactForm.personalName = null
        this.contactForm.email = null
        this.contactForm.phone = null
        this.contactForm.city = null
        this.contactForm.body = null
      }).catch((err) => {
        this.buildViolations(err.response)
        this.contactForm.key++
      })
    }
  }
}

