import App from '../app'
import Vue from 'vue'
import GLightbox from 'glightbox'
import Axios from '../ajax'
import Qs from 'qs';
import { tns } from "tiny-slider"

new Vue({
  extends: App,
  data() {
    return {
      form: {
        name: null,
        email: null,
        regNumber: null,
        object: null,
        unit: null,
        message: null
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      GLightbox({
        width: '600px',
        height: 'auto'
      })

      tns({
        container: '#main-slider',
        mouseDrag: true,
        swipeAngle: false,
        navPosition: 'bottom',
        controls: false,
        autoplay: true,
        speed: 1000
      })

    })
  },
  methods: {
    submitForm() {
      Axios.post('/kontakt', Qs.stringify(this.form))
        .then(() => {
          alert('Formulář byl odeslán')
          this.form = {
            name: null,
            email: null,
            regNumber: null,
            object: null,
            unit: null,
            message: null
          }
        })
        .catch((err) => {
          alert('Formulář nebyl odeslán. Zkontrolujte správnost vyplněných údajů.')
        })
    }
  }
})

