/*
 * This file is part of the ARODAX Admin package.
 *
 * (c) ARODAX a.s.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-disable camelcase */

import has from 'lodash/has'
import set from 'lodash/set'
import get from 'lodash/get'
import map from 'lodash/map'
import unset from 'lodash/unset'

/**
 * This mixin provides various methods to handle errors and validations.
 */
const errorHandlerMixin = {
  data () {
    return {
      errorHandlerMixin_violations: {}
    }
  },
  methods: {
    /**
     * Build violations from the error object
     * @param {error} err error object contains the violations
     */
    buildViolations (err) {
      if (!has(err, 'data.violations')) {
        return
      }

      const violations = {}

      map(err.data.violations, (value) => {
        if (has(value, 'title')) {
          set(violations, value.propertyPath, value.title)
        }

        if (has(value, 'message')) {
          set(violations, value.propertyPath, value.message)
        }
      })

      this.errorHandlerMixin_violations = violations
    },
    /**
     * Build violation at the custom propertyPath
     * @param {error} err Error object
     * @param propertyPath
     */
    buildViolation (err, propertyPath) {
      let message = ''

      if (has(err, 'data.title')) {
        message = message + err.data.title
      }

      if (has(err, 'data.detail')) {
        message = message + ': ' + err.data.detail
      }

      if (!has(this.errorHandlerMixin_violations, propertyPath)) {
        set(this.errorHandlerMixin_violations, propertyPath, [])
      }

      this.errorHandlerMixin_violations[propertyPath].push(message)
    },
    setViolation (message, propertyPath) {

      if (typeof this.errorHandlerMixin_violations === 'undefined') {
        this.$set(this, 'errorHandlerMixin_violations', {})
      }

      if (!has(this.errorHandlerMixin_violations, propertyPath)) {
        set(this.errorHandlerMixin_violations, propertyPath, [])
      }

      this.errorHandlerMixin_violations[propertyPath].push(message)
    },
    /**
     * Clear all violations
     */
    clearViolations () {
      this.errorHandlerMixin_violations = []
    },
    /**
     * Clear violation at the given property path
     * @param propertyPath
     */
    clearViolation (propertyPath) {
      unset(this.errorHandlerMixin_violations, propertyPath)
    },
    /**
     * Check if there is violation for the given propertyPath
     * @param {string} propertyPath The property path of the violation
     * @returns {boolean}
     */
    hasViolation (propertyPath) {
      if (!propertyPath) {
        return false
      }

      return has(this.errorHandlerMixin_violations, propertyPath)
    },
    /**
     * Get the violation at the property path
     * @param {string|array} propertyPath The property path of the violation
     * @returns {boolean}
     */
    getViolation (propertyPath) {
      if (!propertyPath) {
        return false
      }

      if (Array.isArray(propertyPath)) {
        let violation = null

        propertyPath.forEach((path) => {
          if (this.hasViolation(path)) {
            violation = get(this.errorHandlerMixin_violations, path)
          }
        })

        return violation
      } else {
        return get(this.errorHandlerMixin_violations, propertyPath)
      }
    }
  }
}

export { errorHandlerMixin }
