/*
 * This file is part of the zivotpodlevas.cz package.
 *
 *  (c) VSFG
 *
 *  For the full copyright and license information, please view the LICENSE
 *  file that was distributed with this source code.
 */

import Axios from 'axios'

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default Axios
